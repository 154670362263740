
<template>
  <CRow>
    <CCol col="12" lg="8">
      <CCard>
        <CCardHeader>
          User Profile
        </CCardHeader>

        <CCardBody class="p-4"
        >
          <CForm>
            <CRow>
              <CCol col="6" class="text-left">
                <CInput
                    label="Username"
                    placeholder="Username"
                    autocomplete="username"
                    v-model="user.username"
                    disabled
                  >               
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                    label="Phone"
                    placeholder="Phone Number"
                    v-model="userAttributes.phone_number"
                    disabled
                  >               
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                    label="Mail"
                    placeholder="Mail"
                    v-model="userAttributes.email"
                    disabled
                  >               
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>                                
              </CCol>
              
            </CRow>            
          </CForm>
        </CCardBody>          
        <CCardFooter>
          <CButton color="danger" @click="logout">Logout</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
export default {

  data () {
    return {
      user: {},
      userAttributes: {},
      accessToken: null,
      error: null
    }
  },
  created: function() {
    // console.log(this.userAttributes)
    this.user = this.$cognitoAuth.getCurrentUser();
    // console.log(this.user)
    // this.$cognitoAuth.isAuthenticated((result, success) => {
    //   if (success) {
    //       console.log("isAuthenticated")
    //   } else {
    //       console.log("isNotAuthenticated")
    //   }
    // });         
    this.$cognitoAuth.getIdToken((err, result) => {
      if (err) {
        this. error = err
      } else {        
        this.accessToken = result
      }
    })
    this.getUserAttributes((result, success) => {
      if (success) {
        this.userAttributes = result
      }
    })

  },
  methods: {
      logout: function() {
          this.$cognitoAuth.logout()
          this.$router.go()
      },
      getUserAttributes(cb) {
        this.$cognitoAuth.getUserAttributes(function(result, success) {
          if (success) {
            var userAttributes = {}
            for (var key in result) {
              userAttributes[result[key].getName()] = result[key].getValue()
            }         
            return cb(userAttributes, true)
          }
          return cb({}, false)
        });

      },
  }
}
</script>

  getUserAttributes(cb) {
    let cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
      if (err) {          
          return cb(err, false)
      }
      cognitoUser.getUserAttributes(function(err, result) {
        if (err) {
          return cb(err, false)
        }
        return cb(result, true)
      })    
     })  
    }
  }

